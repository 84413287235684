import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"
import {
  faBarcode,
  faBook,
  faBookReader,
  faCashRegister,
  faChartBar,
  faExchangeAlt,
  faHatWizard,
  faPlusCircle,
  faPrint,
  faQuestionCircle,
  faSave,
  faUser,
} from "@fortawesome/free-solid-svg-icons"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import useTranslations from "components/use-translations"

export default function Ozellikler() {
  const {
    genel: { ozellikler },
    kutuphaneOzellikler: {
      seoTitle,
      seoDescription,
      barkodSistemi,
      hazirKitaplar,
      raporSistemi,
      veriAktarmaSihirbazi,
      cokluKullaniciSistemi,
      barkodOkumaYazdirma,
      yedeklemeVeGeriYukleme,
      kasaSistemi,
      okuyucuEkleme,
      sinifYukseltDusur,
      hasarliKayip,
      anindaOkuyucuKaydi,
      barkodSistemiDetay,
      hazirKitaplarDetay,
      raporSistemiDetay,
      veriAktarmaSihirbaziDetay,
      cokluKullaniciSistemiDetay,
      barkodOkumaYazdirmaDetay,
      yedeklemeVeGeriYuklemeDetay,
      kasaSistemiDetay,
      okuyucuEklemeDetay,
      sinifYukseltDusurDetay,
      hasarliKayipDetay,
      anindaOkuyucuKaydiDetay,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/kutuphane-programi/ozellikler"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/kutuphane-programi",
            name: "Kütüphane Programı",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.Kutuphane}
          activeMenuItem={MenuItemType.Ozellikler}
        />
        <Row>
          <Col>
            <h1 className="mb-4">{ozellikler}</h1>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faBarcode} />{" "}
              {barkodSistemi}
            </h5>
            <p>{barkodSistemiDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faBook} />{" "}
              {hazirKitaplar}
            </h5>
            <p>{hazirKitaplarDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faChartBar} />{" "}
              {raporSistemi}
            </h5>
            <p>{raporSistemiDetay}</p>
            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faHatWizard} />{" "}
              {veriAktarmaSihirbazi}
            </h5>
            <p>{veriAktarmaSihirbaziDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faUser} />{" "}
              {cokluKullaniciSistemi}
            </h5>
            <p>{cokluKullaniciSistemiDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faPrint} />
              {barkodOkumaYazdirma}
            </h5>
            <p>{barkodOkumaYazdirmaDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faSave} />{" "}
              {yedeklemeVeGeriYukleme}
            </h5>
            <p>{yedeklemeVeGeriYuklemeDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faCashRegister} />{" "}
              {kasaSistemi}
            </h5>
            <p>{kasaSistemiDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faBookReader} />{" "}
              {okuyucuEkleme}
            </h5>
            <p>{okuyucuEklemeDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faExchangeAlt} />{" "}
              {sinifYukseltDusur}
            </h5>
            <p>{sinifYukseltDusurDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon
                className="text-success"
                icon={faQuestionCircle}
              />{" "}
              {hasarliKayip}
            </h5>
            <p>{hasarliKayipDetay}</p>

            <h5 className="mt-5">
              <FontAwesomeIcon className="text-success" icon={faPlusCircle} />{" "}
              {anindaOkuyucuKaydi}
            </h5>
            <p>{anindaOkuyucuKaydiDetay}</p>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.Kutuphane}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.SikSorulanSorular,
                MenuItemType.EkranGoruntuleri,
                MenuItemType.KullanimKilavuzu,
                MenuItemType.Referanslar,
              ]}
              activeMenuItem={MenuItemType.Ozellikler}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
